import React from "react";
import styled from "styled-components";
import GoogleLogo from "../../content/assets/icons/google-logo.svg";

interface GoogleSSOButtonProps {
  text?: string;
  onClick: () => void;
}

export const GoogleSSOButton: React.FC<GoogleSSOButtonProps> = ({ text, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <img src={GoogleLogo} alt="google" />
      {text}
    </StyledButton>
  );
};

GoogleSSOButton.defaultProps = {
  text: "Sign in with Google",
};

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 327px;
  max-width: 100%;
  padding: 14px;
  border-radius: 32px;
  border: 2px solid #e3e4e5;
  background-color: #fff;
`;
