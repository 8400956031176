import React from "react";
import styled from "styled-components";
import AppleLogo from "../../content/assets/icons/apple-logo.svg";

interface AppleSSOButtonProps {
  text?: string;
  onClick: () => void;
}

export const AppleSSOButton: React.FC<AppleSSOButtonProps> = ({ text, onClick }) => {
  return (
    <StyledButton onClick={onClick}>
      <img src={AppleLogo} alt="apple" />
      {text}
    </StyledButton>
  );
};

AppleSSOButton.defaultProps = {
  text: "Sign in with Apple",
};

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 327px;
  max-width: 100%;
  padding: 14px;
  border-radius: 32px;
  background: #000;
  color: #fff;
`;
